import { Injectable } from '@angular/core';
import { PoiService } from './poi.service';
import { RouteTypesService } from './route-types.service';
import { DurationService } from './duration.service';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    constructor(
        private poiService: PoiService,
        private routeTypeService: RouteTypesService,
        private durationService: DurationService
    ) {
    }

    clear(): void {
        this.poiService.clear();
        this.routeTypeService.clear();
        this.durationService.clear();
    }
}
