import { Component, OnInit } from '@angular/core';
import { DurationService } from '../../core/services/duration.service';
import { Paginator } from '../../core/models/paginator.model';
import { Duration } from '../../core/models/duration.model';
import { TitleService } from '../../core/services/title.service';
import { LoadingService } from '../../core/services/loading.service';
import { PoiService } from '../../core/services/poi.service';
import { Poi } from '../../core/models/poi.model';
import { environment } from '../../../environments/environment';
import { RouteTypesService } from '../../core/services/route-types.service';
import { RouteTypes } from '../../core/models/route-types.model';
import { Location } from '@angular/common';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
    availableDuration: Duration[] = [];
    availablePoi: Poi[] = [];
    availableRouteTypes: RouteTypes[] = [];

    apiUrl = environment.apiUrl;

    constructor(
        private durationService: DurationService,
        private titleService: TitleService,
        private loadingService: LoadingService,
        private poiService: PoiService,
        private routeTypesService: RouteTypesService,
        private location: Location
    ) {
        titleService.pageTitle.next('Filtres');
    }

    ngOnInit(): void {
        this.loadingService.open();
        this.durationService.getDuration().subscribe((result: Paginator) => {
            this.availableDuration = result['hydra:member'] as Duration[];
            this.loadingService.forceClose();
        });
        this.poiService.getPOI().subscribe((data: Paginator) => {
            this.availablePoi = data['hydra:member'] as Poi[];
        });
        this.routeTypesService.getRouteTypes().subscribe((result: Paginator) => {
            this.availableRouteTypes = result['hydra:member'] as RouteTypes[];
        });
    }

    selectDuration(duration: Duration): void {
        const selectedDuration = this.durationService.selectedDuration.getValue();
        if (selectedDuration === duration) {
            this.durationService.selectedDuration.next(null);
            return;
        }
        this.durationService.selectedDuration.next(duration);
    }

    isSelectedDuration(duration: Duration): boolean {
        const selectedDuration = this.durationService.selectedDuration.getValue();
        if (!selectedDuration) { return false; }
        return duration['@id'] === selectedDuration['@id'];
    }

    selectPoi(poi: Poi): void { // toggle method -> if in array then removes it
        const selectedPois = this.poiService.selectedPOIs.getValue();
        const clickedPoiIdx = selectedPois.map(obj => obj.id).indexOf(poi.id);
        if (clickedPoiIdx > -1) { // if not in array (found by id)
            selectedPois.splice(clickedPoiIdx, 1);
        } else {
            selectedPois.push(poi);
        }
        this.poiService.selectedPOIs.next(selectedPois);
    }

    isSelectedPoi(poi: Poi): boolean {
        const selectedPois = this.poiService.selectedPOIs.getValue();
        if (selectedPois.length < 1) {
            return false;
        }
        return selectedPois.map(obj => obj.id).indexOf(poi.id) > -1;
    }

    selectRouteTypes(routeTypes: RouteTypes): void {
        const selectedRouteTypes = this.routeTypesService.selectedRouteTypes.getValue();
        const clickedRTypesIdx = selectedRouteTypes.map(obj => obj.id).indexOf(routeTypes.id);
        if (clickedRTypesIdx > -1) { // if not in array (found by id)
            selectedRouteTypes.splice(clickedRTypesIdx, 1);
        } else {
            selectedRouteTypes.push(routeTypes);
        }
        this.routeTypesService.selectedRouteTypes.next(selectedRouteTypes);
    }

    isSelectedRouteTypes(routeTypes: RouteTypes): boolean {
        const selectedRouteTypes = this.routeTypesService.selectedRouteTypes.getValue();
        if (selectedRouteTypes.length < 1) {
            return false;
        }
        return selectedRouteTypes.map(obj => obj.id).indexOf(routeTypes.id) > -1;
    }

    save(): void {
        this.location.back();
    }
}
