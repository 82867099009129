<div id="map" class="map-container"> </div>

<div class="startGps-wrapper">
    <button class="startGps-btn" mat-flat-button (click)="gotoRun()">{{'map.lets-go' | translate}}</button>
</div>

<div class="icones-carte d-flex align-items-end flex-column">
    <div class="p-1" *ngFor="let mData of metadatas">
        <div class="icone-carte my-0 p-2">
            <div class="p-1">
                <img src="{{apiUrl}}{{mData.icon.contentUrl}}" alt="" />
            </div>
            <p class="mb-2"> {{mData.value}} </p>
        </div>
    </div>
</div>
