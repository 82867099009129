// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://api.guidos.fun',
    clientUrl: 'https://webapp.guidos.fun',
    localClientKey: 'guidos-x-key',
    mapbox: {
        accessToken: 'pk.eyJ1IjoidG9raXdpIiwiYSI6ImNqeGN2Mm10aDA2eXkzcG1saW9xMm8yYTkifQ.3KyIOgt9WLolITw6XuMDpQ'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
