import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './layouts/start/start.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpOptionsInterceptor } from './core/interceptors/http-options-interceptor.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonLoadingDirective } from './core/directives/mat-button-loading.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { HomeComponent } from './views/home/home.component';
import { FiltersComponent } from './views/filters/filters.component';
import { MapComponent } from './views/map/map.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { CardComponent } from './components/card/card.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PoiDialogComponent } from './shared/poi-dialog/poi-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RunComponent } from './views/run/run.component';
import { CodeComponent } from './views/code/code.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InfoDialogComponent } from './shared/info-dialog/info-dialog.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivityComponent } from './layouts/activity/activity.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { ShortcutDialogComponent } from './shared/shortcut-dialog/shortcut-dialog/shortcut-dialog.component';
import { LoadingImageComponent } from './shared/loading-image/loading-image.component';

@NgModule({
    declarations: [
        AppComponent,
        StartComponent,
        MatButtonLoadingDirective,
        LoadingImageComponent,
        LoaderComponent,
        DashboardComponent,
        HomeComponent,
        FiltersComponent,
        MapComponent,
        CardComponent,
        PoiDialogComponent,
        RunComponent,
        CodeComponent,
        InfoDialogComponent,
        ActivityComponent,
        ShortcutDialogComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatChipsModule,
        MatButtonModule,
        OverlayModule,
        MatIconModule,
        MatDialogModule,
        MatCardModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatRippleModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatMenuModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpOptionsInterceptor,
            multi: true
        },
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
