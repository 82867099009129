import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteService } from '../../core/services/route.service';
import { RouteModel } from '../../core/models/route.model';
import { LoadingService } from '../../core/services/loading.service';
import { MapService } from '../../core/services/map.service';
import { environment } from '../../../environments/environment';
import { Metadata } from '../../core/models/metadata.model';
import { ClientService } from '../../core/services/client.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
    apiUrl = environment.apiUrl;
    metadatas: Metadata[] = [];

    constructor(
        public titleService: TitleService,
        private route: ActivatedRoute,
        private routeService: RouteService,
        private loadingService: LoadingService,
        private mapService: MapService,
        private client: ClientService
    ) {
    }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('routeId');
        this.routeService.getSingleRoute(id).subscribe((result: RouteModel) => {
            this.titleService.pageTitle.next(result.name);
            this.mapService.buildMap(result.geoJson.coordinates, result.pointsOfInterest, result.tips, false);
            this.metadatas = result.metaData;
            this.loadingService.forceClose();
        });
    }

    gotoRun(): void {
        const code = this.client.getClientKey();
        const id = this.route.snapshot.paramMap.get('routeId');
        this.loadingService.navigate(`${code}/view/run/${id}`);
    }
}
