import { Component, Inject, OnInit } from '@angular/core';
import { ThemeService } from '../../core/services/theme.service';
import { Activity } from '../../core/models/activity.model';
import { environment } from '../../../environments/environment';
import { ActivitiesService } from '../../core/services/activities.service';
import { Paginator } from '../../core/models/paginator.model';
import { LoadingService } from '../../core/services/loading.service';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../core/services/client.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { FiltersService } from '../../core/services/filters.service';
import { OrganizationService } from '../../core/services/organization.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
    availableActivities: Activity[] = [];
    apiUrl = environment.apiUrl;

    constructor(
        public theme: ThemeService,
        private activitiesService: ActivitiesService,
        private filterService: FiltersService,
        private loading: LoadingService,
        private route: ActivatedRoute,
        private client: ClientService,
        private dialog: MatDialog,
        public organization: OrganizationService,
        public translator: TranslateService,
        @Inject(DOCUMENT) private doc: Document
    ) {
        this.translator.onLangChange.subscribe(() => this.getActivities());
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (this.organization.currentOrganization.getValue()) {
                return;
            }

            this.client.tryKey(params.get('code'));
        });

        this.filterService.clear();
        this.getActivities();
        this.loading.forceClose();
    }

    getActivities(): void {
        const selectedActivity = this.activitiesService.selectedActivity.getValue();

        this.activitiesService.getActivities().subscribe((result: Paginator) => {
            this.availableActivities = result['hydra:member'] as Activity[];
            if (!selectedActivity) {
                this.activitiesService.selectedActivity.next(this.availableActivities[0]);
            }
            setTimeout(() => {
                this.setFontSizes();
            }, 200);
        });
    }

    select(idx: number): void {
        const code = this.client.getClientKey();
        this.activitiesService.selectedActivity.next(this.availableActivities[idx]);
        void this.loading.navigate(code + '/home');
    }

    openInfoModal(): void {
        this.dialog.open(InfoDialogComponent, {
            data: {
                message: this.organization.currentOrganization.getValue().message
            },
            minWidth: '300px'
        });
    }

    changeLang(lang: string): void {
        this.translator.use(lang);
    }

    setFontSizes(): void { // for DE lang -- too long words
        this.doc.querySelectorAll('.act-card').forEach((card: HTMLElement) => {
            const text = card.querySelector('p');
            const textWidth = text.offsetWidth - 2;
            if (textWidth >= card.offsetWidth) {
                const diff = ((textWidth - card.offsetWidth) / (card.offsetWidth)) * 100;
                text.style.fontSize = String((100 - diff) > 80 ? (100 - diff) - 20 : (100 - diff)) + '%';
            }
        });
    }
}
