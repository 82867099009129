import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ActivitiesService } from './activities.service';
import { PoiService } from './poi.service';
import { RouteTypesService } from './route-types.service';
import { DurationService } from './duration.service';

@Injectable({
    providedIn: 'root'
})
export class RouteService {

    constructor(
        private api: ApiService,
        private activityService: ActivitiesService,
        private poiService: PoiService,
        private rtService: RouteTypesService,
        private durationService: DurationService
    ) { }

    getRoutes(): Observable<any> {
        let params = new HttpParams();
        params = params.set('activity.id', String(this.activityService.selectedActivity.getValue().id));
        this.poiService.selectedPOIs.getValue().forEach(poi => {
            params = params.append('pointsOfInterest.type.id[]', String(poi.id));
        });
        const selectedDuration = this.durationService.selectedDuration.getValue();
        if (selectedDuration) {
            params = params.set('routeActivities.duration[' + selectedDuration.direction + ']', String(selectedDuration.minutes));
        }
        this.rtService.selectedRouteTypes.getValue().forEach(rt => {
            params = params.append('routeTypes.id[]', String(rt.id));
        });
        return this.api.get('/routes', { params });
    }

    getSingleRoute(routeId: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('activity.id', String(this.activityService.selectedActivity.getValue().id));
        return this.api.get(`/routes/${routeId}`, {params});
    }
}
