import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../core/services/title.service';
import { Location } from '@angular/common';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    constructor(
        public titleService: TitleService,
        private location: Location,
        private loadingService: LoadingService
    ) { }

    ngOnInit(): void {
    }

    goBack(): void {
        this.loadingService.open();
        setTimeout(() => {
            this.location.back();
        }, 200); // open overlay animation speed
    }
}
