import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-loading-image',
    templateUrl: './loading-image.component.html',
    styleUrls: ['./loading-image.component.scss']
})
export class LoadingImageComponent implements OnInit {
    @Input()
    src: string;

    @Input()
    alt: string;

    @Input()
    loaderStyle: string;

    @Output()
    onload = new EventEmitter<ElementRef>();

    @ViewChild('image') private image: ElementRef;

    loading = true;
    spinnerSrc = 'assets/images/loader.gif';

    constructor() {
    }

    ngOnInit(): void {
    }

    onLoad(): void {
        this.loading = false;
        this.onload.emit(this.image);
    }

}
