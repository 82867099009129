import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Poi } from '../../core/models/poi.model';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-poi-dialog',
    templateUrl: './poi-dialog.component.html',
    styleUrls: ['./poi-dialog.component.scss']
})
export class PoiDialogComponent implements OnInit {
    apiUrl = environment.apiUrl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {poi: Poi},
        private dialogRef: MatDialogRef<PoiDialogComponent>
    ) { }

    ngOnInit(): void {
    }

    close(): void {
        this.dialogRef.close();
    }
}
