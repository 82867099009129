/* eslint-disable */
import { Injectable, OnDestroy } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../environments/environment';
import { Poi } from '../models/poi.model';
import { MatDialog } from '@angular/material/dialog';
import { PoiDialogComponent } from '../../shared/poi-dialog/poi-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapService implements OnDestroy{
    // https://stackoverflow.com/a/61338503 TODO : Bearing if nav
    /*
    MAPBOX STYLES:
        - summer version:     mapbox://styles/tokiwi/ck205usib0vmr1cmht8jpmdul
        - winter version:     mapbox://styles/tokiwi/ckfzd9kgt14i719nioofv40hp
    */
    style = 'mapbox://styles/tokiwi/ck205usib0vmr1cmht8jpmdul';
    mapRef: mapboxgl.Map;
    zoom = 13;

    startTime: Date;
    startPosition: Coordinates = null;
    lastPosition: Coordinates = null;

    elapsedTime = new BehaviorSubject<number>(0);
    elapsedTime$ = this.elapsedTime.asObservable();

    avgSpeed = new BehaviorSubject<number>(0);
    realSpeed = new BehaviorSubject<number>(0) ;

    elapsedDistance = new BehaviorSubject<number>(0);
    elapsedDistance$ = this.elapsedDistance.asObservable();

    timeInterval;

    userHeading = new BehaviorSubject<number>(0);

    isTouchingScreen = new BehaviorSubject<boolean>(false);
    travelledDistance = new BehaviorSubject<number>(0);

    constructor(
        private dialog: MatDialog
    ) {
        mapboxgl.accessToken = environment.mapbox.accessToken;
        window.addEventListener('touchstart', () => { // used to prevent rotating map when touching screen
            this.isTouchingScreen.next(true);
        });
        window.addEventListener('touchend', () => {
            this.isTouchingScreen.next(false);
        });
    }

    // Convert degrees to radians
    static toRad(nb: number): number {
        return nb * Math.PI / 180;
    }
    // Used to calculate the average speed (for now)
    // Calculate distance between 2 coordinates (in km ??)
    static calcCrow(lat1, lon1, lat2, lon2): number
    {
        const R = 6371; // Radius of the earth in km
        const dLat = MapService.toRad(lat2 - lat1);
        const dLon = MapService.toRad(lon2 - lon1);
        lat1 = MapService.toRad(lat1);
        lat2 = MapService.toRad(lat2);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return (R * c);
    }

    ngOnDestroy(): void {
        this.userHeading.unsubscribe();
    }

    clearIntervals(): void {
        clearInterval(this.timeInterval);
    }

    calcTime(): void {
        const now = new Date();
        const diff = Math.abs(now.getTime() - this.startTime.getTime());
        const minutes = Math.floor((diff / 1000) / 60);
        this.elapsedTime.next(minutes);
    }

    initNavigation(): void {
        let newDistance = 0;
        let travelledDistance = 0;

        this.startTime = new Date();

        this.timeInterval = setInterval(() => {
            this.calcTime();
        }, 3000);

        if (navigator.geolocation) {
            // watchPosition is called everytime the user's position is changing
            navigator.geolocation.watchPosition(currentPosition => {
                /*
                if (!this.startPosition) { this.startPosition = position.coords; }
                this.calcTime();
                this.elapsedDistance.next(
                    MapService.calcCrow(
                        position.coords.latitude,
                        position.coords.longitude,
                        this.startPosition.latitude,
                        this.startPosition.longitude)
                );
                */

                // calculate travelled distance
                navigator.geolocation.getCurrentPosition(position => {
                    if (!this.lastPosition) {
                        this.lastPosition = position.coords;
                    }
                    newDistance = MapService.calcCrow(
                        position.coords.latitude,
                        position.coords.longitude,
                        this.lastPosition.latitude,
                        this.lastPosition.longitude);
                    travelledDistance += newDistance;
                    this.elapsedDistance.next(travelledDistance);
                    this.lastPosition = position.coords;
                });
            });
        }
        this.elapsedDistance$.subscribe(() => {
            this.calcSpeed();
        });
        this.elapsedTime$.subscribe(() => {
            this.calcSpeed();
        });
    }

    calcSpeed(): void {
        this.avgSpeed.next(
            this.elapsedTime.getValue() === 0 ? 0 :
                (this.elapsedDistance.getValue() * 1000) / (this.elapsedTime.getValue() * 60) * 3.6 // 3.6 -> m/s to km/h
        );
    }

    buildMap(coordinates: [number, number], POIs: Poi[], tips, navigation: boolean): void {
        const pitch = navigation ? 60 : 0;
        this.mapRef = new mapboxgl.Map({
            container: 'map',
            style: this.style,
            center: coordinates[0],
            zoom: navigation ? 17 : this.zoom,
            pitch
        });

        this.mapRef.on('load', () => {
            if (navigation && true === navigation) {
                const geolocation = new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    trackUserLocation: true,
                });
                this.mapRef.addControl(geolocation);
                setTimeout(() => {
                    geolocation.trigger();
                }, 500);
                this.initNavigation();
                geolocation.on('geolocate', (pos) => {
                    if (pos.coords.speed) { // m/s to km/h : *3.6
                        this.realSpeed.next(pos.coords.speed * 3.6);
                    }
                });
            }
            this.mapRef.addSource('route', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates
                    }
                }
            });
            this.mapRef.addLayer({
                id: 'route',
                type: 'line',
                source: 'route',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#002c52',
                    'line-width': 6
                }
            });
            POIs.forEach((poi) => {
                const el = document.createElement('div');
                el.className = 'marker';
                el.style.width = '40px';
                el.style.height = '40px';
                el.style['background-size'] = 'contain';
                el.style.backgroundImage = 'url(' + environment.apiUrl + poi.type.mapIcon.contentUrl + ')';
                el.addEventListener('click', () => {
                    this.dialog.open(PoiDialogComponent, {
                        data: {
                            poi
                        },
                        panelClass: ['full-screen-modal']
                    });
                });
                new mapboxgl.Marker(el)
                    .setLngLat([poi.longitude, poi.latitude])
                    .setOffset([0, -20])
                    .addTo(this.mapRef);
            });
            tips.forEach((tip) => {
                const el = document.createElement('div');
                el.className = 'marker';
                el.style.width = '40px';
                el.style.height = '40px';
                el.style['background-size'] = 'contain';
                el.style.backgroundImage = 'url(' + environment.apiUrl + tip.type.mapIcon.contentUrl + ')';

                new mapboxgl.Marker(el)
                    .setLngLat([tip.longitude, tip.latitude])
                    .setOffset([0, -20])
                    .addTo(this.mapRef);
            });
            function addStartEndMarker(coord, icon): HTMLElement {
                const el = document.createElement('div');
                el.className = 'marker';
                el.style.width = '42px';
                el.style.height = '46px';
                el.style['background-size'] = 'contain';
                el.style.backgroundImage = 'url("' + icon + '")';
                return el;
            }
            new mapboxgl.Marker(addStartEndMarker(coordinates.slice(-1)[0], '../../assets/icons/end_icon.png'))
                .setLngLat(coordinates.slice(-1)[0])
                .setOffset([0, -20])
                .addTo(this.mapRef);
            new mapboxgl.Marker(addStartEndMarker(coordinates[0], '../../assets/icons/start_icon.png'))
                .setLngLat(coordinates[0])
                .setOffset([0, -20])
                .addTo(this.mapRef);
        });
    }

    // calcNewHeading(event: {webkitCompassHeading?: any; alpha: number}): void {
    //     let newHeading;
    //     if (event.webkitCompassHeading) {
    //         newHeading = event.webkitCompassHeading + 180;
    //     } else {
    //         newHeading = 180 - event.alpha;
    //     }
    //     if (newHeading < 0){
    //         newHeading = 360 - (360 + newHeading);
    //     } else if (newHeading > 0){
    //         newHeading = 360 - newHeading;
    //     }
    //     this.userHeading.next(newHeading);
    // }

}
