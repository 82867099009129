import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Organization } from '../models/organization.model';
import { Paginator } from '../models/paginator.model';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    public currentOrganization: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(null)
    public currentOrganization$ = this.currentOrganization.asObservable();

    constructor(
        private api: ApiService,
        private loading: LoadingService
    ) { }

    getOrganization(): Observable<boolean> {
        const requestFinished = new Subject<boolean>();
        this.loading.loadingSubject$.next(true);
        this.api.get('/organizations').subscribe((result: Paginator) => {
            this.loading.loadingSubject$.next(false);
            this.currentOrganization.next(result['hydra:member'][0]);
            requestFinished.next(true);
        });

        return requestFinished.asObservable();
    }
}
