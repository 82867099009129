<div id="map" class="map-container"> </div>

<div class="speed">
    <h2>{{(mapService.realSpeed | async).toFixed(1)}} km/h</h2>
</div>

<div class="run-footer row ml-0 d-flex align-items-center">
    <div class="time col-6">
        <h3><span style="font-weight: bold">{{mapService.elapsedTime | async}} min </span> <span>{{ 'run.elapsed-time' | translate }}</span></h3>
        <!--<h3><span style="font-weight: bold">{{(mapService.avgSpeed | async).toFixed(1)}} km/h </span> <span>{{ 'run.average' | translate }}</span></h3>-->
        <h3><span style="font-weight: bold">{{(mapService.elapsedDistance | async).toFixed(1)}} km </span> <span>{{ 'run.elapsed-distance' | translate }}</span></h3>

    </div>
    <div class="col-6 d-flex align-items-center justify-content-end">
        <button mat-button (click)="stopNavigation()"><mat-icon>close</mat-icon></button>
    </div>
</div>
