<div class="container">
    <div class="start-header">
        <div class="col-md-12 logo d-flex justify-content-center pt-3">
            <img class="mt-2" src="{{ theme.currentLogo$ | async }}" alt="Logo"/>
        </div>
        <div class="col-5">
            <div class="activities-border">
                <p>{{ 'outdoor-activities' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="start-section col-12 flex-align-center">
        <button class="start-border-ext mx-auto" mat-button (click)="start()">
            <h3 id="start">Start</h3>
        </button>
    </div>

    <div class="start-footer row">
        <div class="col-12 flex-align-center mb-2">
            <p>Powered by</p>
        </div>
        <div class="col-12 flex-align-center">
            <img id="logoGuidos" src="/assets/images/logo-guidos-BLANC.png" alt="" />
        </div>
    </div>
</div>

