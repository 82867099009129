import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Activity } from '../models/activity.model';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {
    public selectedActivity: BehaviorSubject<Activity> = new BehaviorSubject<Activity>(null);

    constructor(
        private api: ApiService
    ) { }

    getActivities(): Observable<any> {
        return this.api.get('/activities');
    }
}
