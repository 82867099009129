<mat-card class="mat-elevation-z5" mat-ripple (click)="navigateToRoute(route['@id'].split('/').pop())">
    <div>
        <mat-chip-list>
            <mat-chip [style]="{'background-color':route.routeActivities[0].level.color }" class="level-chip">{{ route.routeActivities[0].level.name }}</mat-chip>
            <div class="provider">
                <img src="{{apiUrl}}{{ route.provider.logo.contentUrl }}" alt="" class="provider-logo">
            </div>
        </mat-chip-list>
        <div class="main-image">
            <app-loading-image loaderStyle="margin: 50px auto; display: block; max-width: 64px;"
                               (onload)="toggleResize($event)"
                               src="{{apiUrl}}{{ route.images[0].contentUrl }}" alt="{{ route.images[0].originalName }}"></app-loading-image>
            <div class="row icones mx-auto col-12">
                <div class="col-3 p-1" *ngFor="let mData of metadatas">
                    <div class="icone">
                        <div class="p-1">
                            <img src="{{apiUrl}}{{mData.icon.contentUrl}}" alt="" />
                        </div>
                        <p> {{mData.value}} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-card-content class="pt-4 base-top-margin" #content>
        <mat-card-title>{{ route.name }}</mat-card-title>
        <h6 class="location">{{ route.location }}</h6>
        <p class="description">{{ route.description }}</p>
    </mat-card-content>
</mat-card>

