import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ThemeService } from './theme.service';
import { Paginator } from '../models/paginator.model';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { OrganizationService } from './organization.service';
import { Organization } from '../models/organization.model';

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    clientKey = new BehaviorSubject<string>(null);

    constructor(
        private theme: ThemeService,
        private orgs: OrganizationService,
        private router: Router
    ) { }

    getClientKey(): string {
        return this.clientKey.getValue();
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    tryKey(queryKey: string, timeout?: any, callback?: (result?: Organization) =>  void): void {
        if (typeof queryKey === 'undefined' || !queryKey) {
            return;
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        this.clientKey.next(queryKey);

        // now that we have a key -> get theme from API
        this.ensureOrganizationLoad(callback);
    }

    ensureOrganizationLoad(callback?: (result?: Organization) => void): void {
        this.orgs.getOrganization().subscribe(() => {
            const organization = this.orgs.currentOrganization.getValue();

            if (!organization) {
                if (callback) { callback(); }
                return;
            }

            this.theme.setCurrentThemeFromResponse(organization); // persist color code
            if (callback) { callback(organization); } else {
                setTimeout(() => {
                    AppComponent.finishedInitialLoad.next(true);
                }, 300);
            }
        });
    }
}
