import { Injectable } from '@angular/core';

@Injectable()
export class IriUtil {

    public static extractId(iri: string): string {
        const parts = iri.split('/');

        return parts.pop();
    }

    public static checkIfIri(potentialIri: string): boolean {
        const parts = potentialIri.split('/');
        return parts && parts.length > 1;
    }

    public static extractIdAsNumber(iri: string): number {
        return +this.extractId(iri);
    }

    public static extractIds(hydra: any[]): string[] {
        const ret: string[] = [];
        hydra.forEach((iri: Record<any, any>) => {
            ret.push(this.extractId(iri['@id']));
        });
        return ret;
    }

    public static extractIdsAsNumber(hydra: any[]): number[] {
        const ret: number[] = [];
        const str = this.extractIds(hydra);
        str.forEach((value) => {
            ret.push(parseInt(value, 10));
        });
        return ret;
    }

    /**
     * Need same non static method to use as injection (then in templates)
     */
    public extractId(iri: string): string {
        const parts = iri.split('/');

        return parts.pop();
    }
}
