import { Component, Inject, OnInit } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    static finishedInitialLoad = new BehaviorSubject(false);
    finishedInitialLoad$ = AppComponent.finishedInitialLoad.asObservable();

    title = 'guidosWebApp';

    constructor(
        public translate: TranslateService,
        public theme: ThemeService,
        @Inject(DOCUMENT) private doc: Document
    ) {
        translate.setDefaultLang('fr');
        translate.addLangs(['fr', 'en', 'de']);
        translate.use('fr');

        translate.onLangChange.subscribe((lang: {lang: string}) => {
            this.doc.querySelector('html').setAttribute('lang', lang.lang);
        });
    }

    ngOnInit(): void {
    }

}
