<div class="container criteres py-4">
    <div>
        <div class="critere col-12">
            <h3>{{ 'filters.expectations' | translate }}</h3>
        </div>
        <ul class="bloc-filtres col-12 row ml-0 mt-2 d-flex justify-content-space-evenly">
            <li class="detail-critere container m-0 mb-4 col-6" *ngFor="let type of availableRouteTypes">
                <div>
                    <div class="route-types-image" [class.selectedRT]="isSelectedRouteTypes(type)" (click)="selectRouteTypes(type)">
                        <img src="{{ apiUrl }}{{ type.image.contentUrl }}" alt=""/>
                        <span class="material-icons">done</span>
                    </div>
                </div>
                <p>{{ type.name }}</p>
            </li>
        </ul>
    </div>
    <div>
        <div class="critere col-12">
            <h3>{{ 'filters.available-time' | translate }}</h3>
        </div>
        <ul class="bloc-filtres col-12 row ml-0 mt-2 d-flex justify-content-space-evenly">
            <li class="detail-critere container m-0 col-4" *ngFor="let duration of availableDuration">
                <div class="mb-4">
                    <div class="carre-icone icon-time-wrp" [class.selected]="isSelectedDuration(duration)" (click)="selectDuration(duration)">
                        <div class="icon-time">
                            <span>{{ duration.label }}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div>
        <div class="critere col-12">
            <h3>{{ 'filters.other-needs' | translate }}</h3>
        </div>
        <ul class="bloc-filtres col-12 row ml-0 mt-2 d-flex justify-content-space-evenly">
            <li class="detail-critere container m-0 mb-4 col-4" *ngFor="let poi of availablePoi">
                <div>
                    <div class="carre-icone icon-time-wrp" [class.selected]="isSelectedPoi(poi)" (click)="selectPoi(poi)">
                        <div>
                            <img src="{{ apiUrl }}/{{ poi.icon.contentUrl }}" alt="{{ poi.name }}"/>
                        </div>
                    </div>
                </div>
                <p>{{ poi.name }}</p>
            </li>
        </ul>
    </div>
</div>

<div class="save-wrapper">
    <button class="save-btn" mat-flat-button (click)="save()">{{ 'register' | translate }}</button>
</div>
