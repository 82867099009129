import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Duration } from '../models/duration.model';
import { ActivitiesService } from './activities.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DurationService {
    public selectedDuration: BehaviorSubject<Duration> = new BehaviorSubject<Duration>(null);

    constructor(
        private api: ApiService,
        private activityService: ActivitiesService
    ) { }

    getDuration(): Observable<any> {
        const currentActivity = this.activityService.selectedActivity.getValue();
        const id = String(currentActivity ? currentActivity.id : 1);
        const params = new HttpParams().set('activity.id', id);
        return this.api.get('/durations/', { params });
    }

    clear(): void {
        this.selectedDuration.next(null);
    }
}
