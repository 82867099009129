<header class="header-wrp flex-align-center">
    <!-- Define header with conditions : home or not, title, etc -->
    <button *ngIf="titleService.showBackButton" mat-button class="back_button" (click)="goBack()">
        <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <h2>{{titleService.pageTitle$ | async }}</h2>
</header>
<!-- /home children will come here (defined in routing) -->
<div class="content-wrapper">
    <router-outlet></router-outlet>
</div>
