import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganizationService } from '../../../core/services/organization.service';

@Component({
    selector: 'app-shortcut-dialog',
    templateUrl: './shortcut-dialog.component.html',
    styleUrls: ['./shortcut-dialog.component.scss']
})
export class ShortcutDialogComponent {

    constructor(
        public dialog: MatDialog,
        public organization: OrganizationService,
        private dialogRef: MatDialogRef<ShortcutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {icon: string}
    ) {}

    close(): void {
        this.dialogRef.close();
    }
}
