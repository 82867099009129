import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientService } from '../services/client.service';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class HttpOptionsInterceptor implements HttpInterceptor {
    constructor(
        public clientService: ClientService,
        @Inject(DOCUMENT) private doc: Document
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                'accept-language': this.doc.querySelector('html').getAttribute('lang'),
                'X-Auth-Key': `${this.clientService.getClientKey()}`
            }
        });
        return next.handle(request);
    }
}
