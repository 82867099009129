import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../core/services/client.service';
import { LoadingService } from '../../core/services/loading.service';
import { MatButtonLoadingDirective } from '../../core/directives/mat-button-loading.directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.scss'],
    providers: [
        MatButtonLoadingDirective
    ]
})
export class CodeComponent implements AfterViewInit {
    @ViewChild('input') private inputs: ElementRef;

    @ViewChild('no1') private no1: ElementRef;
    @ViewChild('no2') private no2: ElementRef;
    @ViewChild('no3') private no3: ElementRef;
    @ViewChild('no4') private no4: ElementRef;
    @ViewChild('no5') private no5: ElementRef;
    @ViewChild('no6') private no6: ElementRef;

    form = this.fb.group({
        no1: null,
        no2: null,
        no3: null,
        no4: null,
        no5: null,
        no6: null
    });

    isButtonLoading = false;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private clientService: ClientService,
        private loading: LoadingService,
        private snack: MatSnackBar
    ) {
        AppComponent.finishedInitialLoad.next(true);
    }

    ngAfterViewInit(): void {
        for (let i = 1; i < 7; i++) {
            this.form.get('no' + String(i)).valueChanges.subscribe(newVal => {
                if (String(newVal).trim().length > 0 && i < 6) {
                    const el = this['no' + String(i + 1)] as ElementRef;
                    (el.nativeElement as HTMLElement).focus();
                } else if (String(newVal).trim().length < 1 && i > 1) {
                    const el = this['no' + String(i - 1)] as ElementRef;
                    (el.nativeElement as HTMLElement).focus();
                }
            });
        }
    }

    hasValue(ctrlName: string): boolean {
        if (null === this.form.get(ctrlName).value) {
            return false;
        }

        return String(this.form.get(ctrlName).value).trim().length > 0;
    }

    continue(): void {
        this.isButtonLoading = true;
        const final = this.form.value as Array<{key: string; val: number}>;
        let finalString = '';
        Object.keys(final).forEach(key => {
            finalString += final[key];
        });

        this.clientService.tryKey(finalString, null, (success) => {
            if (success) {
                void this.loading.navigate(finalString);
            } else {
                this.snack.open('Veuillez vérifier le code inséré.', 'OK');
            }
            this.isButtonLoading = false;
        });
    }

}
