import { ComponentRef, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from '../../components/loader/loader.component';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject$.asObservable();

    private openedOverlayCount = 0;

    private loadingRef: OverlayRef;

    constructor(
        private injector: Injector,
        private overlay: Overlay,
        private router: Router
    ) {
    }

    private static attachDialogContainer(overlayRef: OverlayRef): LoaderComponent {
        const containerPortal = new ComponentPortal(LoaderComponent);
        const containerRef: ComponentRef<LoaderComponent> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }

    startLoading(): void {
        this.loadingSubject$.next(true);
    }

    stopLoading(): void {
        this.loadingSubject$.next(false);
    }

    isLoading(): boolean {
        return this.loadingSubject$.getValue();
    }

    /**
     * Overlay function
     */
    navigate(url: string): void {
        setTimeout(() => {
            this.open();
            setTimeout(() => {
                void this.router.navigateByUrl(url);
            }, 300);
        }, 300);
    }

    open(): void {
        if (this.openedOverlayCount === 0) {
            const overlayRef = this.createOverlay();
            LoadingService.attachDialogContainer(overlayRef);
            this.loadingRef = overlayRef;
        }

        ++this.openedOverlayCount;
    }

    forceClose(): void {
        if (!this.loadingRef || !this.loadingRef.overlayElement) {
            return;
        }

        this.loadingRef.overlayElement.classList.add('fade-out');

        setTimeout(() => {
            this.loadingRef.dispose();
        }, 300);

        this.openedOverlayCount = 0;
    }

    close(): void {
        --this.openedOverlayCount;

        if (this.openedOverlayCount === 0) {
            this.forceClose();
        }
    }

    fakeLoad(): void {
        this.open();
        setTimeout(() => {
            this.close();
        }, 600);
    }

    private createOverlay(): OverlayRef {
        const positionStrategy = this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically();
        const overlayConfig = new OverlayConfig({
            panelClass: 'animate',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return this.overlay.create(overlayConfig);
    }
}
