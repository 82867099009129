<div class="container">
    <div class="header row">
        <div class="col-5 logo py-2">
            <img src="{{ theme.currentLogo$ | async }}" alt="Logo">
        </div>
        <div class="col-2 my-auto activites-outdoor">
            {{ 'outdoor-activities' | translate }}
        </div>
        <div class="col-2"></div>
        <div class="col-3">
            <div class="my-auto language-flag" [matMenuTriggerFor]="menu" mat-ripple>
                <div class="my-1">
                    <img src="assets/icons/{{translator.currentLang}}.svg" alt="">
                </div>
            </div>

            <mat-menu #menu="matMenu" style="text-align: left;">
                <button mat-menu-item (click)="changeLang('fr')">
                    <img src="../../../assets/icons/fr.svg" style="width: 20px;" alt="">
                    <span> Français</span>
                </button>
                <button mat-menu-item (click)="changeLang('de')">
                    <img src="../../../assets/icons/de.svg" style="width: 20px;" alt="">
                    <span> Deutsch</span>
                </button>
                <button mat-menu-item (click)="changeLang('en')">
                    <img src="../../../assets/icons/en.svg" style="width: 20px;" alt="">
                    <span> English</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="fixed-center">
        <h1 class="title">{{ 'activity.choice-of-activity' | translate }}</h1>
        <div class="tab-wrapper p-2 row" *ngIf="availableActivities.length">
            <ng-container *ngFor="let activity of availableActivities; let i = index;">
                <div class="col-4 p-2 d-flex justify-content-center">
                    <div class="act-card" mat-ripple (click)="select(i)">
                        <div>
                            <app-loading-image src="{{apiUrl}}/{{activity.image.contentUrl}}"></app-loading-image>
                            <p>{{ activity.name }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="!availableActivities.length" class="d-flex justify-content-center align-items-center" style="min-height: 20vh">
            <mat-spinner diameter="20"></mat-spinner>
        </div>
    </div>

    <div class="fixed-end row" *ngIf="(organization.currentOrganization$ | async)?.message">
        <div class="col-12">
            <h1 class="title">{{ 'activity.informations' | translate }}</h1>
            <button mat-button class="big-btn" (click)="openInfoModal()">{{(organization.currentOrganization$ | async)?.message.title}}</button>
        </div>
    </div>

</div>



