import { Component, Inject, OnInit } from '@angular/core';
import { ThemeService } from '../../core/services/theme.service';
import { LoadingService } from '../../core/services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../core/services/client.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Manifest } from '../../core/models/manifest.model';
import { ShortcutDialogComponent } from '../../shared/shortcut-dialog/shortcut-dialog/shortcut-dialog.component';
import { OrganizationService } from '../../core/services/organization.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { ManifestService } from '../../core/services/manifest.service';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
    sessionLoginTimeout;
    hideA2HS = false;

    constructor(
        public theme: ThemeService,
        private loadingService: LoadingService,
        private route: ActivatedRoute,
        private client: ClientService,
        private router: Router,
        @Inject(DOCUMENT) private doc: Document,
        public organization: OrganizationService,
        private dialog: MatDialog,
        private manifestService: ManifestService
    ) {

    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const code = params.get('code');
            this.client.tryKey(code, null, (result) => {
                if (!result) {
                    return;
                }

                this.loadManifest();
                AppComponent.finishedInitialLoad.next(true);
                this.loadingService.forceClose();
            });
        });
    }

    start(): void {
        const code = this.client.getClientKey();
        this.loadingService.navigate(code + '/activity');
    }

    iOS(): boolean {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    private loadManifest(): void {
        // https://stackoverflow.com/a/65809255
        this.manifestService.getManifest().subscribe(manifest => {
            const stringManifest = JSON.stringify(manifest);
            const blob = new Blob([stringManifest], {type: 'application/json'});
            const manifestUrl = URL.createObjectURL(blob);
            this.doc.querySelector('#manifest-placeholder').setAttribute('href', manifestUrl);

            if (this.iOS()) {
                this.loadIOSIcons(manifest);
            }
        });
    }

    private loadIOSIcons(manifest: Manifest): void {
        let i = 0;
        let firstIcon: string = null;

        manifest.icons.forEach(icon => {
            if (manifest.icons.length - 1 === i) {
                firstIcon = icon.src;
            }

            const newIcon = this.doc.createElement('link');
            newIcon.setAttribute('rel', 'apple-touch-icon');
            newIcon.setAttribute('href', icon.src);
            newIcon.setAttribute('sizes', icon.sizes);
            this.doc.getElementsByTagName('head')[0].append(newIcon);
            ++i;
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const navigator: {standalone: boolean} = window.navigator as any;
        if (navigator.standalone === true) {
            return;
        }

        this.dialog.open(ShortcutDialogComponent, {
            data: {
                icon: firstIcon
            }
        });
    }
}
