<div class="col-12 py-4" #containerRef>
    <div [hidden]="!isLoading" class="loading-fixed">
        <div class="d-flex justify-content-center align-items-center mt-6">
            <mat-spinner diameter="30" strokeWidth="1"></mat-spinner>
        </div>
    </div>
    <div [hidden]="isLoading || availableRoutes.length > 1" class="loading-fixed">
        <div class="d-flex justify-content-center align-items-center mt-6">
            {{ 'home.no-result' | translate }}
        </div>
    </div>
    <div *ngFor="let route of availableRoutes" [hidden]="isLoading || availableRoutes.length < 1">
        <app-card [route]="route" class="mb-4"></app-card>
    </div>
</div>

<div class="settings-icon-wrp d-flex justify-content-end">
    <button mat-fab (click)="gotoSettings()"  class="settings-icon">
        <img src="/assets/icons/filter.png" alt="filter">
    </button>
</div>
