import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouteModel } from '../../core/models/route.model';
import { RouteService } from '../../core/services/route.service';
import { TitleService } from '../../core/services/title.service';
import { LoadingService } from '../../core/services/loading.service';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../core/services/map.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-run',
    templateUrl: './run.component.html',
    styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit, OnDestroy{
    constructor(
        private routeService: RouteService,
        private titleService: TitleService,
        private loadingService: LoadingService,
        private route: ActivatedRoute,
        public mapService: MapService,
        private location: Location,
    ) {
        titleService.showBackButton = false;
    }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('routeId');
        this.routeService.getSingleRoute(id).subscribe((result: RouteModel) => {
            this.titleService.pageTitle.next(result.name);
            this.mapService.buildMap(result.geoJson.coordinates, result.pointsOfInterest, result.tips, true);
            this.loadingService.forceClose();
        });
    }

    ngOnDestroy(): void {
        this.titleService.showBackButton = true;
    }

    stopNavigation(): void {
        this.mapService.clearIntervals();
        this.loadingService.open();
        setTimeout(() => {
            this.location.back();
        }, 200); // open overlay animation speed
    }

}
