<div>
    <div class="cross">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="row">
        <div class="col-4">
            <img class="img-fluid" [src]="data.icon">
        </div>
        <div class="col-8">
            <h2>{{organization.currentOrganization.getValue().legalName}} {{ 'outdoor-activities' | translate }}</h2>
        </div>
    </div>
    <p>{{ 'shortcut.intro' | translate: { organiName: this.organization.currentOrganization.getValue().legalName } }}</p>
    <h3>{{ 'shortcut.add-this-app' | translate }}</h3>
    <p [innerHTML]="'shortcut.two-steps' | translate"></p>
</div>
