import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    pageTitle: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    pageTitle$ = this.pageTitle.asObservable();

    showBackButton = true;

    constructor() { }
}
