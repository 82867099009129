<div class="container">
    <div class="logo row">
        <div class="col-12 mt-5">
            <img src="assets/images/logo-guidos-BLANC.png" alt="">
        </div>
    </div>
    <div class="row">
        <div class="col-8 mx-auto mt-4">
            <p>{{ 'code.discover-guidos' | translate }}</p>
        </div>
    </div>
    <div class="row activationKey">
        <div class="col-8 mx-auto mt-6">
            <p>{{ 'code.enter.activation-key' | translate }}</p>
        </div>
    </div>
    <div class="code-section row" [formGroup]="form">
        <input type="text" class="code-input" maxlength="1" formControlName="no1" [class.blueBorder]="hasValue('no1')" #no1 autofocus="autofocus">

        <input type="text" class="code-input" maxlength="1" formControlName="no2" [class.blueBorder]="hasValue('no2')" #no2>

        <input type="text" class="code-input" maxlength="1" formControlName="no3" [class.blueBorder]="hasValue('no3')" #no3>

        <input type="text" class="code-input" maxlength="1" formControlName="no4" [class.blueBorder]="hasValue('no4')" #no4>

        <input type="text" class="code-input" maxlength="1" formControlName="no5" [class.blueBorder]="hasValue('no5')" #no5>

        <input type="text" class="code-input" maxlength="1" formControlName="no6" [class.blueBorder]="hasValue('no6')" #no6>
    </div>
    <div class="row d-flex justify-content-center py-4">
        <button mat-button class="confirmCode" (click)="continue()" appMatButtonLoading [loading]="isButtonLoading">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>
