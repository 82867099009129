import { Component, Input, ViewChild, ElementRef, OnChanges, HostListener } from '@angular/core';
import { RouteModel } from '../../core/models/route.model';
import { environment } from '../../../environments/environment';
import { LoadingService } from '../../core/services/loading.service';
import { Metadata } from '../../core/models/metadata.model';
import { ClientService } from '../../core/services/client.service';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnChanges {
    @Input()
    public route: RouteModel;

    @ViewChild('content') private content: ElementRef;

    apiUrl = environment.apiUrl;
    metadatas: Metadata[] = [];

    constructor(
        private loading: LoadingService,
        private client: ClientService
    ) { }

    navigateToRoute(routeId: string): void {
        const code = this.client.getClientKey();
        this.loading.navigate(`${code}/view/route/${routeId}`);
    }

    ngOnChanges(): void {
        this.metadatas = this.route.metaData;
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 10);
    }

    toggleResize(image: ElementRef): void {
        setTimeout(() => {
            const height = (image.nativeElement as HTMLElement).clientHeight;
            (this.content.nativeElement as HTMLElement).style.marginTop = String(height - 30) + 'px';
        });
    }
}
