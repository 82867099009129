import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './layouts/start/start.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { HomeComponent } from './views/home/home.component';
import { FiltersComponent } from './views/filters/filters.component';
import { MapComponent } from './views/map/map.component';
import { RunComponent } from './views/run/run.component';
import { CodeComponent } from './views/code/code.component';
import { ActivityComponent } from './layouts/activity/activity.component';
import { CodeGuard } from './core/guards/code.guard';

const routes: Routes = [
    {
        path: 'code',
        component: CodeComponent,
        pathMatch: 'full'
    },
    {
        path: ':code',
        component: StartComponent
    },
    {
        path: ':code/activity',
        component: ActivityComponent
    },
    {
        path: ':code/home',
        component: DashboardComponent,
        canActivate: [CodeGuard],
        children: [
            {
                path: '',
                component: HomeComponent
            }
        ]
    },
    {
        path: ':code/view',
        component: DashboardComponent,
        canActivate: [CodeGuard],
        children: [
            {
                path: 'filters',
                component: FiltersComponent
            },
            {
                path: 'route/:routeId',
                component: MapComponent
            },
            {
                path: 'run/:routeId',
                component: RunComponent
            }
        ]
    },
    {
        path: '**',
        component: CodeComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
