import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Theme } from '../models/color-theme.model';
import { Paginator } from '../models/paginator.model';
import { Organization } from '../models/organization.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    currentTheme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>({
        '--primary-color': '#151515'
    });
    currentTheme$ = this.currentTheme.asObservable();

    currentLogo: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    currentLogo$ = this.currentLogo.asObservable();

    constructor(
    ) { }

    setCurrentThemeFromResponse(org: Organization): void{
        this.setCurrentThemePrimaryColor(org);
        this.setCurrentLogo(org);
    }

    setCurrentThemePrimaryColor(org: Organization): void {
        this.currentTheme.next({
            '--primary-color': org.colorCode
        });
    }

    setCurrentLogo(org: Organization): void {
        const logoUrl = `${environment.apiUrl}/${org.logo.contentUrl}`;
        this.currentLogo.next(logoUrl);
    }
}
