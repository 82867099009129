import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { ActivitiesService } from '../services/activities.service';
import { TitleService } from '../services/title.service';
import { ClientService } from '../services/client.service';
import { OrganizationService } from '../services/organization.service';

@Injectable({
    providedIn: 'root'
})
export class CodeGuard implements CanActivate {
    constructor(
        private loadingService: LoadingService,
        public activitiesService: ActivitiesService,
        private titleService: TitleService,
        private client: ClientService,
        private orgs: OrganizationService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const code = next.paramMap.get('code');
        if (!code) {
            return true;
        }

        if (null === this.activitiesService.selectedActivity.getValue()) {
            void this.loadingService.navigate(code + '/activity');
            return false;
        }

        if (this.orgs.currentOrganization.getValue()) {
            return true;
        }

        this.client.tryKey(code, null, cb => {
            if (cb) {
                if (null === this.activitiesService.selectedActivity.getValue()) {
                    void this.loadingService.navigate(code + '/activity');
                } else {
                    this.titleService.pageTitle.next(this.activitiesService.selectedActivity.getValue().name);
                }
            }
        });
        return true;
    }

}
