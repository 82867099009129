import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ActivitiesService } from './activities.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { RouteTypes } from '../models/route-types.model';

@Injectable({
    providedIn: 'root'
})
export class RouteTypesService {
    selectedRouteTypes: BehaviorSubject<RouteTypes[]> = new BehaviorSubject<RouteTypes[]>([]);

    constructor(
        private api: ApiService,
        private activityService: ActivitiesService
    ) { }

    getRouteTypes(): Observable<any> {
        const selectedActivity = this.activityService.selectedActivity.getValue();
        let params = new HttpParams();
        if (!selectedActivity) {
            params = params.set('activity.id', String(1));
        } else {
            params = params.set('activity.id', String(selectedActivity.id));
        }
        return this.api.get('/route_types', { params });
    }

    clear(): void {
        this.selectedRouteTypes.next([]);
    }
}
