import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { LoadingService } from '../../core/services/loading.service';
import { ActivitiesService } from '../../core/services/activities.service';
import { Paginator } from '../../core/models/paginator.model';
import { environment } from '../../../environments/environment';
import { ThemeService } from '../../core/services/theme.service';
import { RouteModel } from '../../core/models/route.model';
import { RouteService } from '../../core/services/route.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { ClientService } from '../../core/services/client.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from '../../core/services/title.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    @ViewChild('containerRef') containerRef: ElementRef;

    apiUrl = environment.apiUrl;

    availableRoutes: RouteModel[] = [];

    isLoading = true;

    constructor(
        public dialog: MatDialog,
        private loadingService: LoadingService,
        public activitiesService: ActivitiesService,
        public theme: ThemeService,
        private routeService: RouteService,
        private client: ClientService,
        private route: ActivatedRoute,
        private titleService: TitleService
    ) { }

    ngOnInit(): void {
        this.getRoutes();
    }

    gotoSettings(): void {
        const code = this.client.getClientKey();
        this.loadingService.navigate(code + '/view/filters');
    }

    getRoutes(): void {
        this.isLoading = true;
        this.routeService.getRoutes().subscribe((result: Paginator) => {
            this.availableRoutes = result['hydra:member'] as RouteModel[];
            this.loadingService.forceClose();
            this.isLoading = false;
            window.scroll(0, 0);
            window.dispatchEvent(new Event('resize'));
        });
    }

    openDialog(): void {
        this.dialog.open(InfoDialogComponent, {
            panelClass: ['full-screen-modal']
        });
    }
}
