import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Manifest } from '../models/manifest.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Organization } from '../models/organization.model';
import { HttpParams } from '@angular/common/http';
import { OrganizationService } from './organization.service';
import { IriUtil } from './iriutil.service';

@Injectable({
    providedIn: 'root'
})
export class ManifestService {
    constructor(
        private api: ApiService,
        private organization: OrganizationService
    ) {
    }

    getManifest(): Observable<Manifest> {
        const currentOrganizationId = IriUtil.extractId(this.organization.currentOrganization.getValue()['@id']);
        const params = (new HttpParams()).set('organization', currentOrganizationId);
        return this.api.get('/manifest', {params}).pipe(map((res: { manifest: Manifest }) => res.manifest));
    }
}
